import Utils from 'truemarket-modules/src/Utils'

export default class ValidationError {
  Id = Utils.GetId();
  Key: string;
  Error: string;

  constructor (key: string, error: string) {
    this.Key = key
    this.Error = error
  }
}
