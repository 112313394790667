import FormField from './FormField'

export default class FileUploadField extends FormField {
  Multiple: boolean;
  ContentTypes: string[];
  Capture: boolean;

  constructor (init: Partial<FileUploadField>) {
    init.Type = 'file'

    super(init)

    this.Multiple = init.Multiple ?? false
    this.ContentTypes = init.ContentTypes ?? []
    this.Capture = init.Capture ?? false
  }
}
