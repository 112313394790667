





























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Steps extends Vue {
  @Prop({ required: true })
  private readonly steps!: string[];

  @Prop()
  private readonly validationIndex!: number;

  @Prop({ default: null })
  private readonly beforeNext!: ((index: number) => Promise<boolean>) | null;

  @Prop({ default: -1 })
  private readonly minStep!: number;

  private index = 0

  private waitNext = false

  goBack (): void {
    if (this.index === 0) return

    this.index -= 1

    this.$emit('navigated', this.index)

    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  goNext (): void {
    if (this.index + 1 === this.steps.length) return

    if (this.validationIndex < this.index) return

    if (this.beforeNext) {
      this.waitNext = true

      this.beforeNext(this.index + 1).then((next) => {
        this.waitNext = false

        if (next) {
          this.$emit('next')

          this.index += 1

          this.$emit('navigated', this.index)
        }
      })

      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0

      return
    }

    this.$emit('next')

    this.index += 1

    this.$emit('navigated', this.index)
  }
}
