














import { DropdownField, DropdownListItem, FormField, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import IValuationFormStep from '../IValuationFormStep'
import ValuationFormStep from './ValuationFormStep.vue'

@Component({
  components: {
    Form,
    ValuationFormStep
  }
})
export default class AssessmentDetails extends Vue implements IValuationFormStep {
  private fields: FormField[] | null = null

  loading = true

  errored = false

  mounted (): void {
    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.ValuationPurpose).then((vals) => {
      this.fields = [
        new DropdownField({
          Key: 'AssessmentDetails_Purpose',
          Label: 'Purpose:',
          Placeholder: 'Please Select',
          Items: vals.filter((f) => { return f.Value !== 'GeneralInformation' }).map((v) => ({ Label: v.Label, Key: v.ValueId })),
          Required: true
        }),
        new FormField({
          Key: 'AssessmentDetails_CurrentDate',
          Type: 'date',
          Label: 'Date of Assessment:',
          Placeholder: '',
          Required: true
        })
      ]

      this.loading = false
    }).catch(() => {
      this.errored = true
    })
  }

  public collectData (): FormValue[] {
    return (this.$refs.mainForm as Form).GetValues().Fields
  }
}
