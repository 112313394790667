








import { DropdownField, FormDef, FormField, FormFieldGroup, FormValue } from '@/app_code/Forms'
import Checkbox from '@/components/UI/Elements/Forms/Checkbox.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { EnumerationValueModel, RequestValuationModel } from 'truemarket-modules/src/models/api'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'
import { Services } from 'truemarket-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import IValuationFormStep from '@/components/Valuation/IValuationFormStep'

@Component({
  components: {
    Form
  }
})
export default class RetailStrataRetailSpaceDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  private enumVals: EnumerationValueModel[][] = []

  public collectData (): FormValue[] {
    return (this.$refs.mainForm as Form).GetValues().Fields
  }

  mounted (): void {
    const promises = [
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FloorConstructionType), // 0
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.WallConstructionType), // 1
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RoofConstructionType), // 2
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RetailAccommodationFeatures), // 3
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RetailPropertyFeatures), // 4
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyCondition) // 5
    ]

    Promise.all(promises).then((vals) => {
      this.loading = false

      this.enumVals = vals

      this.fields = [
        new FormFieldGroup({
          Key: 'StrataRetailSpace',
          Title: 'Strata Retail Space',
          ShowTitle: true,
          Fields: [
            new FormField({
              Key: 'Retail_UseDescription',
              Label: 'Use Description:',
              Placeholder: 'e.g. Clothing shop, Café or Newsagent',
              Required: true
            }),
            new DropdownField({
              Key: 'Retail_GeneralCondition',
              Label: 'General Condition:',
              Placeholder: 'Please Select',
              Items: vals[5].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new FormField({
              Key: 'Retail_ApproximateBuildYear',
              Label: 'Approximate Building Year:',
              Placeholder: '(eg 1990s)',
              Required: false
            }),
            new FormField({
              Key: 'Retail_BuildingArea',
              Label: 'Building Area:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Retail_ExclusiveUseArea',
              Label: 'Exclusive Use Area (Off Title) - if applicable:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Retail_CarParks',
              Label: 'Designated Car Parks:',
              Placeholder: '',
              Required: false
            })
          ]
        }),
        new FormFieldGroup({
          Key: 'ConstructionDetails',
          Title: 'Construction Details',
          ShowTitle: true,
          Fields: [
            new DropdownField({
              Key: 'Retail_FloorConstruction',
              Label: 'Floors:',
              Placeholder: 'Please Select',
              Items: vals[0].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Retail_WallConstruction',
              Label: 'Walls:',
              Placeholder: 'Please Select',
              Items: vals[1].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Retail_RoofConstruction',
              Label: 'Roof:',
              Placeholder: 'Please Select',
              Items: vals[2].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            })
          ]
        }),
        new FormFieldGroup({
          Key: 'PropertyIncome',
          Title: 'Property Income',
          ShowTitle: true,
          Fields: [
            new FormField({
              Key: 'Retail_AnnualNetRental',
              Label: 'Current Annual Net Rental:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Retail_AnnualGrossRental',
              Label: 'Current Annual Gross Rental:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Retail_AnnualOutgoings',
              Label: 'Current Annual Outgoings:',
              Placeholder: '',
              Required: false
            })
          ]
        }),
        new FormFieldGroup({
          Key: 'AccommodationFeatures',
          Title: 'Accommodation Features',
          ShowTitle: true,
          Fields: [
            ...(vals[3].map((v) => new FormField({ Key: 'Retail_AccommodationFeatures', Value: v.ValueId, Composite: true, Label: v.Label, Type: 'checkbox' })))
          ]
        }),
        new FormFieldGroup({
          Key: 'PropertyFeatures',
          Title: 'Property Features',
          ShowTitle: true,
          Fields: [
            ...(vals[4].map((v) => new FormField({ Key: 'Retail_PropertyFeatures', Value: v.ValueId, Composite: true, Label: v.Label, Type: 'checkbox' })))
          ]
        }),
        new FormField({
          Key: 'EstimatedValue',
          Label: 'Estimated Value / Current Book Value:',
          Placeholder: '',
          Required: false
        })
      ]
    })
  }

  mutateGroup (groupPrefix: string, desiredNumber: number, groupCreator: (idx: number, groupKey: string) => FormFieldGroup): void {
    if (!this.fields) return

    if (isNaN(desiredNumber)) return

    const existingCount = this.fields.filter((f) => f.Key.startsWith(groupPrefix)).length
    const diff = desiredNumber - existingCount

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        this.fields.push(groupCreator(existingCount + i, `${groupPrefix}_${existingCount + i}`))
      }
    } else {
      var absDiff = Math.abs(diff)

      for (let i = 0; i < absDiff; i++) {
        const idx = this.fields.map((f) => f.Key.startsWith(groupPrefix)).lastIndexOf(true)

        if (idx !== -1) this.fields.splice(idx, 1)
      }
    }
  }

  handleFieldUpdated (data: FormField): void {
    if (!this.fields) return

    if (data.Key === 'Outbuildings') {
      const num = parseInt(data.Value as string, 10)

      this.mutateGroup('OutbuildingsDetails_', num, (i, groupKey) => {
        return new FormFieldGroup({
          Key: groupKey,
          Title: `Outbuilding ${i + 1}`,
          ShowTitle: true,
          Fields: [
          ]
        })
      })
    }
  }
}
