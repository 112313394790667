



















import { Component, Vue } from 'vue-property-decorator'
import Steps from '../UI/Elements/Steps/Steps.vue'
import AddressDetails from './Steps/AddressDetails.vue'
import AssessmentDetails from './Steps/AssessmentDetails.vue'
import ContactDetails from './Steps/ContactDetails.vue'
import PropertyTypeSelection from './Steps/PropertyTypeSelection.vue'
import Payment from './Steps/Payment.vue'
import PropertyDetails from './Steps/PropertyDetails.vue'
import OptionalInfo from './Steps/OptionalInfo.vue'
import { RequestValuationContactModel, RequestValuationModel, RequestValuationPropertyClassificationModel } from 'truemarket-modules/src/models/api'
import IValuationFormStep, { IValuationFormModelBuilderStep } from './IValuationFormStep'
import { FormValue } from '@/app_code/Forms'
import { Services } from 'truemarket-modules/src/services'
import { GetContactModel } from 'truemarket-modules/src/models/api/crm'
import IAsyncStep from './IAsyncStep'
import { InvoiceModel } from 'truemarket-modules/src/models/api/invoices'
import GetPropertySnapshotModel from 'truemarket-modules/src/models/api/properties/GetPropertySnapshotModel'
import Notice from '../Notice.vue'

@Component({
  components: {
    Steps,
    AddressDetails,
    AssessmentDetails,
    ContactDetails,
    PropertyTypeSelection,
    Payment,
    PropertyDetails,
    OptionalInfo,
    Notice
  }
})
export default class ValuationForm extends Vue {
  private model: RequestValuationModel = {
    PropertyClassification: null
  }

  paymentInfo = {
    contactId: '',
    invoiceId: ''
  }

  minStep = -1

  property: GetPropertySnapshotModel | null = null

  private validationIndex = -1

  private displayPleaseWait = false

  private paymentProcessed = false

  processPayment (): Promise<void> {
    return new Promise((resolve, reject) => {
      this.displayPleaseWait = true
      setTimeout(() => {
        this.displayPleaseWait = false
        resolve()
      }, 5000)
    })
  }

  handleNext (): void {
    this.model.PropertyClassification = (this.$refs.propertyTypeSelection as unknown as IValuationFormModelBuilderStep).buildModel() as RequestValuationPropertyClassificationModel

    console.log(this.model)
  }

  handleNavigated (index: number): void {
    if (index === 7) {
      // this.$router.push('/completed')
    }
  }

  handlePaymentCompleted (invoice: InvoiceModel): void {
    this.minStep = 7 // was 5
    this.paymentInfo.invoiceId = invoice.InvoiceId
  }

  handleGotContact (contact: GetContactModel): void {
    this.paymentInfo.contactId = contact.ContactId
  }

  stepsBeforeNext: (nextIdx: number) => Promise<boolean> = async (nextIdx: number) => {
    (this.model as any).Contact = (this.$refs.contactDetails as unknown as IValuationFormModelBuilderStep).buildModel() as RequestValuationContactModel

    const nextStepComponent = (this.$refs.steps as any).$slots.default[nextIdx - 1].componentInstance as Vue

    if ('OnBeforeNext' in nextStepComponent) {
      const canGoNext = await (nextStepComponent as unknown as IAsyncStep).OnBeforeNext()

      if (!canGoNext) {
        return false
      }
    }

    if (nextIdx === 3) {
      this.model = this.collectData() as any
    }

    if (nextIdx === 7) { // was 5
      const model = this.collectData() as any
      const pre = await Services.API.ValuationForm.CreatePreValuationForm(model)

      // At this point here we're also going to create the actual valuation
      if (this.paymentInfo.contactId && this.paymentInfo.invoiceId) await Services.API.ValuationForm.CreateValuation(pre.PropertySnapshotId, model.AssessmentDetails.Purpose, this.paymentInfo.invoiceId, model.AssessmentDetails.CurrentDate)

      sessionStorage.setItem('PropertySnapshotId', pre.PropertySnapshotId)
    }

    if (nextIdx === 7) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const model = this.collectData() as any

      try {
        this.property = await Services.API.ValuationForm.SavePropertyDetails(model, sessionStorage.getItem('PropertySnapshotId'))

        this.$router.push('/completed') // COMMENT THIS OUT FOR TESTING
      } catch (ex) {
        console.error(ex)
      }

      return true
    }

    return true
  }

  handleValidated (stepIdx: number, valid: boolean): void {
    console.log(stepIdx, valid)

    if (!valid) {
      this.validationIndex = stepIdx - 1
    } else if (this.validationIndex < stepIdx) {
      this.validationIndex = stepIdx
    }
  }

  collectData (): Record<string, unknown> {
    const refs: IValuationFormStep[] = [
      'addressDetails',
      'assessmentDetails',
      'propertyTypeSelection',
      'contactDetails',
      'propertyDetails',
      'optionalInfo'
    ].map((refName) => this.$refs[refName] as unknown as IValuationFormStep)

    const data: FormValue[] = []

    refs.forEach((ref) => {
      data.push(...ref.collectData())
    })

    const processed: Record<string, unknown> = {}

    function buildTree (parts: string[], ref: Record<string, unknown>, datum: FormValue) {
      const part = parts.shift()

      if (!part) return

      if (parts.length === 0) {
        // This is the last part, so we must set the value
        if (datum.IsComposite) {
          // If its a composite datum, we must check to see if a value
          // has already been set, if so, we append it, else we create a new one

          // If the value is false we will skip over this part
          if (datum.Value as unknown !== false && datum.Value !== '') {
            if (ref[part]) {
              // Push the new item onto the existing array
              (ref[part] as Array<unknown>).push(datum.Value)
            } else {
              // Set the value to an array with just this value
              ref[part] = [datum.Value]
            }
          }
        } else {
          // Else if its not a composite key, we can just set the value normally
          ref[part] = datum.Value
        }

        return
      }

      // If we got here it means there are still more parts
      if (ref[part] === undefined) ref[part] = {}
      // Recursively call buildTree
      buildTree(parts, ref[part] as Record<string, unknown>, datum)

      // eslint-disable-next-line @typescript-eslint/ban-types
      var keys = Object.keys(ref[part] as object)

      if (keys.every((k) => k.toString() === parseInt(k, 10).toString())) {
        // eslint-disable-next-line @typescript-eslint/ban-types
        ref[part] = Object.values(ref[part] as object)
      }
    }

    console.log(data)

    try {
      // Merge non database fields into other
      const other = data.find(element => element.Key === 'Residential_Other')
      const floorLevel = data.find(element => element.Key === 'Residential_FloorLevel')
      other.Value += ' \r\n ' + 'Floor Level: ' + floorLevel.Value
    } catch {}

    data.forEach(datum => {
      buildTree(datum.Key.split('_'), processed, datum)
    })

    console.log(processed)

    return processed
  }
}
