












import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class Checkbox extends Vue {
  @Prop({ required: true })
  private readonly value!: string;

  private val = this.value;

  @Prop({ required: true })
  private readonly label!: string;

  private isChecked = false;

  mounted () {
    this.handleValChanged()
  }

  @Watch('value')
  handleValChanged () {
    const cb = this.$refs.cb as HTMLInputElement

    this.isChecked = cb.checked

    if (this.value) this.val = this.value
  }

  handleUpdated () {
    const cb = this.$refs.cb as HTMLInputElement

    this.isChecked = cb.checked

    // this.$emit('input', this.value)
    this.$emit('input', cb.checked ? this.val : '')
  }
}
