






import { FormField } from '@/app_code/Forms'
import { Component, Vue, Prop } from 'vue-property-decorator'

import Checkbox from './Checkbox.vue'

@Component({
  components: {
    Checkbox
  }
})
export default class Checkboxes extends Vue {
  @Prop({ required: true })
  private fields!: FormField[];

  handleFieldInput (field: FormField, value: string): void {
    this.$emit('input', { field: field, value: value })
  }
}
