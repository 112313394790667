

























import { FormField } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import Checkout from '@/components/UI/Elements/Payment/Checkout.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import { API, ServiceManager, Services, ServiceType } from 'truemarket-modules/src/services'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ValuationFormStep from './ValuationFormStep.vue'
import IAsyncStep from '../IAsyncStep'
import { InvoiceModel } from 'truemarket-modules/src/models/api/invoices'
import { GetContactModel } from 'truemarket-modules/src/models/api/crm'
import { GetProductModel } from 'truemarket-modules/src/models/api/products'
import TMLoader from '@/components/UI/TMLoader.vue'

@Component({
  components: {
    ValuationFormStep,
    Form,
    Checkout,
    TMLoader
  }
})
export default class Payment extends Vue implements IAsyncStep {
  @Prop({ required: true })
  private readonly model!: RequestValuationModel

  private api = ServiceManager.Require<API>(ServiceType.API)

  private product: GetProductModel | null = null

  private invoice: InvoiceModel | null = null

  paymentProcessing = false

  async mounted (): Promise<void> {
    this.updatePrice()
  }

  @Watch('model.PropertyClassification.PropertyClassification')
  async updatePrice (): Promise<void> {
    var productId = this.model.PropertyClassification?.PropertyClassification

    if (productId) {
      this.product = await this.api.Product.GetProduct(productId)
    }
  }

  private fields: FormField[] = [
    new FormField({
      Key: 'AgreeToTOS',
      Type: 'checkbox',
      Value: 'true',
      Label: '<b>I agree to the <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a></b>',
      Placeholder: '',
      ValidationMessage: 'You must agree to the Terms and Conditions to continue',
      Required: true
    })
  ]

  async OnBeforeNext (): Promise<boolean> {
    this.paymentProcessing = true

    const checkout = this.$refs.checkout as Checkout

    const success = await checkout.OnBeforeNext()

    if (success) {
      this.$emit('payment_completed', checkout.GetInvoice())
    } else {
      this.paymentProcessing = false
    }

    return success
  }
}
