














import { DropdownField, FormDef, FormField, FormFieldGroup, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { IValuationFormStepModel, RequestValuationPropertyClassificationModel } from 'truemarket-modules/src/models/api'
import EnumerationValueModel from 'truemarket-modules/src/models/api/enumerations/EnumerationValueModel'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import IValuationFormStep, { IValuationFormModelBuilderStep } from '../IValuationFormStep'
import ValuationFormStep from './ValuationFormStep.vue'

@Component({
  components: {
    Form,
    ValuationFormStep
  }
})
export default class PropertyTypeSelection extends Vue implements IValuationFormStep, IValuationFormModelBuilderStep {
  private fields: FormDef | null = null

  private propertyTypes: EnumerationValueModel[] | null = null;

  loading = true

  errored = false

  mounted (): void {
    const promises = [
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyType),
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyCondition)
    ]

    Promise.all(promises).then((vals) => {
      this.propertyTypes = vals[0]

      this.loading = false

      this.fields = [
        new DropdownField({
          Key: 'PropertyTypeSelection_PropertyType',
          Label: 'Property Type:',
          Placeholder: 'Please Select',
          Items: this.propertyTypes?.map((v) => ({ Label: v.Label, Key: v.ValueId })),
          Required: true
        }),
        new FormFieldGroup({
          Key: 'PropertySubclassificationDetails',
          Display: false,
          Fields: [
            new DropdownField({
              Key: 'PropertyTypeSelection_PropertyClassification',
              Label: 'Property Classification:',
              Value: '',
              Placeholder: 'Please Select',
              Items: [],
              Required: true
            })
          ]
        })
      ]
    }).catch(() => {
      this.errored = true
    })
  }

  buildModel (): IValuationFormStepModel {
    return (this.$refs.form as Form).GetValues().BuildModel<RequestValuationPropertyClassificationModel>()
  }

  public collectData (): FormValue[] {
    return (this.$refs.form as Form).GetValues().Fields
  }

  handleFieldUpdated (data: FormField): void {
    if (!this.fields) return

    if (data.Key === 'PropertyTypeSelection_PropertyType') {
      if (!this.propertyTypes) return

      const subclassificationGroup = this.fields.find((f) => f.Key === 'PropertySubclassificationDetails')

      if (!subclassificationGroup) return

      subclassificationGroup.Display = true

      const propertyTypeSelection = this.propertyTypes.find((v) => v.ValueId === data.Value)

      if (!propertyTypeSelection) return

      Services.API.Enumerations.GetEnumValueChildren(propertyTypeSelection.ValueId, [EnumValueType.PropertySubType]).then((vals) => {
        if (!this.fields) return

        ((this.fields[1] as FormFieldGroup).Fields[0] as DropdownField).Items = vals.map((v) => ({ Label: v.Label, Key: v.ValueId }))
      }).catch(() => {
        this.errored = true
      })
    }
  }
}
