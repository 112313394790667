









import { FormDef, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { EnumerationValueModel, RequestValuationModel } from 'truemarket-modules/src/models/api'
import { Component, Prop, Vue } from 'vue-property-decorator'

import RuralHobbyFarmDetails from './PropertySubTypes/RuralHobbyFarmDetails.vue'
import RuralGrazingFarmDetails from './PropertySubTypes/RuralGrazingFarmDetails.vue'
import RuralCroppingFarmDetails from './PropertySubTypes/RuralCroppingFarmDetails.vue'
import RuralVacantLandDetails from './PropertySubTypes/RuralVacantLandDetails.vue'
import IValuationFormStep from '../../IValuationFormStep'

@Component({
  components: {
    Form,
    RuralHobbyFarmDetails,
    RuralGrazingFarmDetails,
    RuralCroppingFarmDetails,
    RuralVacantLandDetails
  }
})
export default class RuralPropertyDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  private enumVals: EnumerationValueModel[][] = []

  hobbyFarmId = '3e57a63a-f81b-40a1-a77f-26a1b47308e6'
  grazingFarmId = 'ebdd433c-68ce-4d42-8bde-75af231952ea'
  croppingFarmId = '4b174a58-ccaa-4a96-b774-b092e07d8bf7'
  vacantLandId = '9bd0510e-aeb2-4ac9-aa0d-f9ca123faffa'

  get isHobbyFarm (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.hobbyFarmId
  }

  get isGrazingFarm (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.grazingFarmId
  }

  get isCroppingFarm (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.croppingFarmId
  }

  get isVacantLand (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.vacantLandId
  }

  public collectData (): FormValue[] {
    const refs = [
      'RuralHobbyFarmDetails',
      'RuralGrazingFarmDetails',
      'RuralCroppingFarmDetails',
      'RuralVacantLandDetails'
    ]
      .map((refName) => this.$refs[refName])
      .filter((ref) => ref)
      .map((ref) => ref as unknown as IValuationFormStep)
      .map((ref) => ref.collectData())

    return ([] as FormValue[]).concat.apply([], refs)
  }
}
