import Utils from 'truemarket-modules/src/Utils'
import { Validator } from './Validation'

class FormField {
  Id: string = Utils.GetId();
  Type: 'text' | 'email' | 'tel' | 'select' | 'textarea' | 'password' | 'date' | 'richtext' | 'url' | 'checkbox' | 'number' | 'checkboxes' | 'file' | 'address';
  Placeholder?: string;
  Label: string;
  Key: string;
  Value?: string;
  Display: boolean;
  Rows?: string;
  Required: boolean;
  Validator: Validator | null;
  Composite: boolean;
  ValidationMessage: string | null;

  get ValidationLabel (): string {
    return this.ValidationMessage ?? `${this.Label.split(':')[0] ?? this.Key} - required`
  }

  constructor (init: Partial<FormField>) {
    this.Type = init.Type ?? 'text'
    this.Placeholder = init.Placeholder
    this.Label = init.Label ?? ''
    this.Key = init.Key ?? Utils.GetId()
    this.Value = init.Value ?? ''
    this.Display = init.Display ?? true
    this.Rows = init.Rows ?? '3'
    this.Required = init.Required ?? false
    this.Validator = init.Validator ?? null
    this.Composite = init.Composite ?? false
    this.ValidationMessage = init.ValidationMessage ?? null
  }
}

export default FormField
