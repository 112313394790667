import Utils from 'truemarket-modules/src/Utils'
import FormField from './FormField'

class FormFieldGroup {
  Id: string = Utils.GetId();
  IsGroup = true;
  Key: string;
  Title?: string;
  ShowTitle: boolean;
  Display: boolean;
  Fields!: FormField[];

  constructor (init: Partial<FormFieldGroup>) {
    this.Title = init.Title
    this.Key = init.Key ?? ''
    this.ShowTitle = init.ShowTitle ?? false
    this.Display = init.Display ?? true
    this.Fields = init.Fields ?? []
  }
}

export default FormFieldGroup
