











import { FormField, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { IValuationFormStepModel, RequestValuationContactModel } from 'truemarket-modules/src/models/api'
import { API, ServiceManager, Services, ServiceType } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import IAsyncStep from '../IAsyncStep'
import IValuationFormStep, { IValuationFormModelBuilderStep } from '../IValuationFormStep'
import ValuationFormStep from './ValuationFormStep.vue'

@Component({
  components: {
    Form,
    ValuationFormStep
  }
})
export default class ContactDetails extends Vue implements IValuationFormStep, IValuationFormModelBuilderStep, IAsyncStep {
  private fields: FormField[] = [
    new FormField({ Key: 'Contact_FirstName', Label: 'First Name:', Placeholder: 'Your first name', Required: true }),
    new FormField({ Key: 'Contact_LastName', Label: 'Last Name:', Placeholder: 'Your last name', Required: true }),
    new FormField({ Key: 'Contact_Email', Label: 'Email:', Placeholder: 'Your email address', Type: 'email', Required: true }),
    new FormField({ Key: 'Contact_Phone', Label: 'Phone:', Placeholder: 'Your phone number', Type: 'number', Required: true }),
    new FormField({ Key: 'Contact_BusinessName', Label: 'Business Name (optional):', Placeholder: 'Your business name', Required: false })
  ]

  public collectData (): FormValue[] {
    return (this.$refs.form as Form).GetValues().Fields
  }

  buildModel (): IValuationFormStepModel | IValuationFormStepModel[] {
    return (this.$refs.form as Form).GetValues().BuildModel<RequestValuationContactModel>()
  }

  async OnBeforeNext (): Promise<boolean> {
    const api = ServiceManager.Require<API>(ServiceType.API)

    const model = (this.$refs.form as Form).GetValues().BuildModel<RequestValuationContactModel>()

    // onst preModel = this.collectData() as any

    const contact = await api.CRM.CreateContact({
      FirstName: model.FirstName,
      LastName: model.LastName,
      Email: model.Email,
      Phone: model.Phone,
      RequestBearerToken: true,
      BusinessName: model.BusinessName
    }, true)

    this.$emit('got_contact', contact)

    return true
  }
}
