










import ValuationForm from '@/components/Valuation/ValuationForm.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ValuationForm
  }
})
export default class GetAValuation extends Vue {

}
