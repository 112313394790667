












import { FormDef, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import { Component, Prop, Vue } from 'vue-property-decorator'

import CommercialStrataOfficeSuiteDetails from './PropertySubTypes/CommercialStrataOfficeSuiteDetails.vue'
import CommercialStrataMedicalSuiteDetails from './PropertySubTypes/CommercialStrataMedicalSuiteDetails.vue'
import CommercialFreestandingOfficePropertyDetails from './PropertySubTypes/CommercialFreestandingOfficePropertyDetails.vue'
import CommercialFreestandingMedicalPropertyDetails from './PropertySubTypes/CommercialFreestandingMedicalPropertyDetails.vue'
import CommercialMultitenantOfficePremisesDetails from './PropertySubTypes/CommercialMultitenantOfficePremisesDetails.vue'
import CommercialMultitenantMedicalPremisesDetails from './PropertySubTypes/CommercialMultitenantMedicalPremisesDetails.vue'
import CommercialVacantLandDetails from './PropertySubTypes/CommercialVacantLandDetails.vue'
import IValuationFormStep from '../../IValuationFormStep'

@Component({
  components: {
    Form,
    CommercialStrataOfficeSuiteDetails,
    CommercialStrataMedicalSuiteDetails,
    CommercialFreestandingOfficePropertyDetails,
    CommercialFreestandingMedicalPropertyDetails,
    CommercialMultitenantOfficePremisesDetails,
    CommercialMultitenantMedicalPremisesDetails,
    CommercialVacantLandDetails
  }
})
export default class CommercialPropertyDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  strataOfficeSuiteId = '9c74e801-1cee-4608-8f1d-6ac37d414492'
  strataMedicalSuiteId = '4d03c4ae-a819-438e-8132-5c40963c2641'
  freestandingOfficePropertyId = '899806dd-5680-435e-beb8-f90c7503635f'
  freestandingMedicalPropertyId = '0d935727-112b-4c3b-942b-ae0e95264126'
  multitenantOfficePremisesId = 'dab82a5f-6aac-4a11-8cc1-d95b0eaaf66e'
  multitenantMedicalPremisesId = '207e0dba-cd9a-4a42-90e5-9047cbe8a159'
  vacantLandId = '1c666298-f2d6-4f19-8b38-a47fc3987212'

  get isStrataOfficeSuite (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.strataOfficeSuiteId
  }

  get isStrataMedicalSuite (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.strataMedicalSuiteId
  }

  get isFreestandingOfficeProperty (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.freestandingOfficePropertyId
  }

  get isFreestandingMedicalProperty (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.freestandingMedicalPropertyId
  }

  get isMultitenantOfficePremises (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.multitenantOfficePremisesId
  }

  get isMultitenantMedicalPremises (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.multitenantMedicalPremisesId
  }

  get isVacantLand (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.vacantLandId
  }

  public collectData (): FormValue[] {
    const refs = [
      'CommercialStrataOfficeSuiteDetails',
      'CommercialStrataMedicalSuiteDetails',
      'CommercialFreestandingOfficePropertyDetails',
      'CommercialFreestandingMedicalPropertyDetails',
      'CommercialMultitenantOfficePremisesDetails',
      'CommercialMultitenantMedicalPremisesDetails',
      'CommercialVacantLandDetails'
    ]
      .map((refName) => this.$refs[refName])
      .filter((ref) => ref)
      .map((ref) => ref as unknown as IValuationFormStep)
      .map((ref) => ref.collectData())

    return ([] as FormValue[]).concat.apply([], refs)
  }
}
