enum EnumValueType {
  None = 0,

  ValuationPurpose = 3,

  PropertyType = 1,
  PropertySubType = 2,

  PropertyCondition = 4,

  Bedrooms = 5,
  Bathrooms = 6,
  Parking = 7,

  FloorConstructionType = 8,
  WallConstructionType = 9,
  RoofConstructionType = 10,
  
  ResidentialPropertyFeature = 11,

  CommercialAccommodationFeatures = 12,
  CommercialPropertyFeatures = 13,

  IndustrialAccommodationFeatures = 14,
  IndustrialPropertyFeatures = 15,

  RetailAccommodationFeatures = 16,
  RetailPropertyFeatures = 17,

  CityViews = 18,
  RiverViews = 19,
  ElevatedOutlook = 20,
  Pool = 21,
  TennisCourt = 22,
  SecureParkingSpaces = 23,

  ResidentialPropertyFeatures = 24,

  RuralPropertyFeatures = 25,

  Dams = 26,
  Bores = 27,
  FencedPaddocks = 28,
  Outbuildings = 29,
  AirConditioningType = 30
}

export default EnumValueType
