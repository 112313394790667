









import { FormDef, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import { Component, Prop, Vue } from 'vue-property-decorator'

import RetailStrataRetailSpaceDetails from './PropertySubTypes/RetailStrataRetailSpaceDetails.vue'
import RetailFreestandingRetailPropertyDetails from './PropertySubTypes/RetailFreestandingRetailPropertyDetails.vue'
import RetailShowroomBulkyGoodsDetails from './PropertySubTypes/RetailShowroomBulkyGoodsDetails.vue'
import RetailStripShopRetailPropertyDetails from './PropertySubTypes/RetailStripShopRetailPropertyDetails.vue'
import IValuationFormStep from '../../IValuationFormStep'

@Component({
  components: {
    Form,
    RetailStrataRetailSpaceDetails,
    RetailFreestandingRetailPropertyDetails,
    RetailShowroomBulkyGoodsDetails,
    RetailStripShopRetailPropertyDetails
  }
})
export default class RetialPropertyDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  strataRetailSpaceId = '3d445670-01a4-4cfe-a984-5f89f0ef9510'
  freestandingRetailPropertyId = '26dc47da-b5ac-490d-89e5-d7a252373159'
  showroomBulkyGoodsRetailPropertyId = '3ad9c502-3e6e-4dac-8b4f-f8f6b7fe5832'
  stripShopRetailPropertyId = 'c49656af-cd78-4906-a078-c1b5cf505996'

  get isStrataRetailSpace (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.strataRetailSpaceId
  }

  get isFreestandingRetailProperty (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.freestandingRetailPropertyId
  }

  get isShowroomBulkyGoodsRetailProperty (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.showroomBulkyGoodsRetailPropertyId
  }

  get isStripShopRetailProperty (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.stripShopRetailPropertyId
  }

  public collectData (): FormValue[] {
    const refs = [
      'RetailStrataRetailSpaceDetails',
      'RetailFreestandingRetailPropertyDetails',
      'RetailShowroomBulkyGoodsDetails',
      'RetailStripShopRetailPropertyDetails'
    ]
      .map((refName) => this.$refs[refName])
      .filter((ref) => ref)
      .map((ref) => ref as unknown as IValuationFormStep)
      .map((ref) => ref.collectData())

    return ([] as FormValue[]).concat.apply([], refs)
  }
}
