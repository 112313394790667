








import { DropdownField, FormDef, FormField, FormFieldGroup, FormValue } from '@/app_code/Forms'
import Checkbox from '@/components/UI/Elements/Forms/Checkbox.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { EnumerationValueModel, RequestValuationModel } from 'truemarket-modules/src/models/api'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'
import { Services } from 'truemarket-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import IValuationFormStep from '@/components/Valuation/IValuationFormStep'

@Component({
  components: {
    Form
  }
})
export default class ResidentialBlockOfApartmentsDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  private enumVals: EnumerationValueModel[][] = []

  public collectData (): FormValue[] {
    return (this.$refs.mainForm as Form).GetValues().Fields
  }

  mounted (): void {
    const promises = [
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FloorConstructionType), // 0
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.WallConstructionType), // 1
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RoofConstructionType), // 2
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.ResidentialPropertyFeatures), // 3
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyCondition), // 4
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.CityViews), // 5
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RiverViews), // 6
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.ElevatedOutlook), // 7
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Pool), // 8
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.TennisCourt), // 9
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.SecureParkingSpaces), // 10
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bedrooms), // 11
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bathrooms), // 12
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Parking), // 13
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.AirConditioningType) // 14
    ]

    Promise.all(promises).then((vals) => {
      this.loading = false

      this.enumVals = vals

      this.fields = [
        new FormFieldGroup({
          Key: 'BlockOfApartments',
          Title: 'Block of Apartments',
          ShowTitle: true,
          Fields: [
            new DropdownField({
              Key: 'Residential_GeneralCondition',
              Label: 'General Condition:',
              Placeholder: 'Please Select',
              Items: vals[4].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Residential_FloorConstruction',
              Label: 'Floors:',
              Placeholder: 'Please Select',
              Items: vals[0].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Residential_WallConstruction',
              Label: 'Walls:',
              Placeholder: 'Please Select',
              Items: vals[1].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Residential_RoofConstruction',
              Label: 'Roof:',
              Placeholder: 'Please Select',
              Items: vals[2].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new FormField({
              Key: 'Residential_ApproximateBuildYear',
              Label: 'Approximate Building Year:',
              Placeholder: '(eg 1990s)',
              Required: false
            }),
            new FormField({
              Key: 'Residential_NumberOfApartments',
              Label: 'Number of Apartments:',
              Placeholder: '',
              Type: 'number',
              Required: true
            }),
            new DropdownField({
              Value: '',
              Key: 'Residential_CarParks',
              Label: 'Car Parking:',
              Placeholder: 'Please Select',
              Items: vals[13].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            })
          ]
        })
      ]
    })
  }

  mutateGroup (groupPrefix: string, desiredNumber: number, groupCreator: (idx: number, groupKey: string) => FormFieldGroup): void {
    if (!this.fields) return

    if (isNaN(desiredNumber)) return

    const existingCount = this.fields.filter((f) => f.Key.startsWith(groupPrefix)).length
    const diff = desiredNumber - existingCount

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        this.fields.push(groupCreator(existingCount + i, `${groupPrefix}_${existingCount + i}`))
      }
    } else {
      var absDiff = Math.abs(diff)

      for (let i = 0; i < absDiff; i++) {
        const idx = this.fields.map((f) => f.Key.startsWith(groupPrefix)).lastIndexOf(true)

        if (idx !== -1) this.fields.splice(idx, 1)
      }
    }
  }

  handleFieldUpdated (data: FormField): void {
    if (!this.fields) return

    if (data.Key === 'Residential_NumberOfApartments') {
      const num = parseInt(data.Value as string, 10)

      this.mutateGroup('BlockOfApartmentsDetails_', num, (i, groupKey) => {
        return new FormFieldGroup({
          Key: groupKey,
          Title: `Apartment ${i + 1}`,
          ShowTitle: true,
          Display: true,
          Fields: [
            new DropdownField({
              Value: '',
              Key: `Residential_BlockOfApartmentsDetails_${i}_Bedrooms`,
              Label: 'Bedrooms:',
              Placeholder: 'Please Select',
              Items: this.enumVals[11].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Value: '',
              Key: `Residential_BlockOfApartmentsDetails_${i}_Bathrooms`,
              Label: 'Bathrooms:',
              Placeholder: 'Please Select',
              Items: this.enumVals[12].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new FormField({
              Key: `Residential_BlockOfApartmentsDetails_${i}_FloorArea`,
              Label: 'Floor Area:',
              Placeholder: 'e.g. 100 m²',
              Required: false
            }),
            new DropdownField({
              Key: `Residential_BlockOfApartmentsDetails_${i}_SecureParkingSpaces`,
              Label: 'Secure Parking Spaces:',
              Placeholder: 'Please Select',
              Items: this.enumVals[10].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: false
            }),
            new DropdownField({
              Key: `Residential_BlockOfApartmentsDetails_${i}_ApartmentsWithCityViews`,
              Label: 'Apartment with City Views:',
              Placeholder: 'Please Select',
              Items: this.enumVals[5].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: false
            }),
            new DropdownField({
              Key: `Residential_BlockOfApartmentsDetails_${i}_ApartmentsWithRiverViews`,
              Label: 'Apartment with Water Views:',
              Placeholder: 'Please Select',
              Items: this.enumVals[6].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: false
            }),
            new DropdownField({
              Key: `Residential_BlockOfApartmentsDetails_${i}_ApartmentsWithElevatedOutlook`,
              Label: 'Apartment with Elevated Outlook:',
              Placeholder: 'Please Select',
              Items: this.enumVals[7].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: false
            }),
            new DropdownField({
              Key: `Residential_BlockOfApartmentsDetails_${i}_Pool`,
              Label: 'Pool:',
              Placeholder: 'Please Select',
              Items: this.enumVals[8].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: false
            }),
            new DropdownField({
              Key: `Residential_BlockOfApartmentsDetails_${i}_TennisCourt`,
              Label: 'Tennis Court:',
              Placeholder: 'Please Select',
              Items: this.enumVals[9].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: false
            })
          ]
        })
      })
    }
  }
}
