













import { FormDef, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { EnumerationValueModel, RequestValuationModel } from 'truemarket-modules/src/models/api'
import { Component, Prop, Vue } from 'vue-property-decorator'

import ResidentialDuplexDetails from './PropertySubTypes/ResidentialDuplexDetails.vue'
import ResidentialBlockOfApartmentsDetails from './PropertySubTypes/ResidentialBlockOfApartmentsDetails.vue'
import ResidentialTownhouseComplexDetails from './PropertySubTypes/ResidentialTownhouseComplexDetails.vue'
import ResidentialDetatchedResidenceDetails from './PropertySubTypes/ResidentialDetatchedResidenceDetails.vue'
import ResidentialApartmentDetails from './PropertySubTypes/ResidentialApartmentDetails.vue'
import ResidentialTownhouseDetails from './PropertySubTypes/ResidentialTownhouseDetails.vue'
import ResidentialTerraceHouseDetails from './PropertySubTypes/ResidentialTerraceHouseDetails.vue'
import ResidentialVacantLandDetails from './PropertySubTypes/ResidentialVacantLandDetails.vue'
import IValuationFormStep from '../../IValuationFormStep'

@Component({
  components: {
    Form,
    ResidentialDuplexDetails,
    ResidentialBlockOfApartmentsDetails,
    ResidentialTownhouseComplexDetails,
    ResidentialDetatchedResidenceDetails,
    ResidentialApartmentDetails,
    ResidentialTownhouseDetails,
    ResidentialTerraceHouseDetails,
    ResidentialVacantLandDetails
  }
})
export default class ResidentialPropertyDetails extends Vue implements IValuationFormStep {
  @Prop()
  private readonly model!: RequestValuationModel

  private enumVals: EnumerationValueModel[][] = []

  duplexId = 'cc80ffa3-b40d-4816-b7b8-b2fa86e1598f'

  blockOfApartmentsId = '9f2f8397-261d-45e0-b813-16b2518c3760'

  townhouseComplexId = '9ca89f78-85db-4c39-854b-31090a98fed5'

  detatchedResidenceId = '5b14fff0-8a46-46b6-b376-53739f32afa0'

  apartmentId = '3964dc78-5a6f-4395-aa83-3548351db5ba'

  townHouseId = 'd3658cf8-3862-44d1-8648-b777e5d9efd9'

  terraceHouseId = 'df5dead7-179b-4820-be1c-eca6733cdfc1'

  vacantLandId = '9df25ed4-de03-4257-82ae-4e89e712053b'

  get isDuplex (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.duplexId
  }

  get isBlockOfApartments ():boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.blockOfApartmentsId
  }

  get isTownhouseComplex ():boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.townhouseComplexId
  }

  get isDetatchedResidence ():boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.detatchedResidenceId
  }

  get isApartment ():boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.apartmentId
  }

  get isTownHouse ():boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.townHouseId
  }

  get isTerraceHouse ():boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.terraceHouseId
  }

  get isVacantLand ():boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.vacantLandId
  }

  public collectData (): FormValue[] {
    const refs = [
      'ResidentialDuplexDetails',
      'ResidentialBlockOfApartmentsDetails',
      'ResidentialTownhouseComplexDetails',
      'ResidentialDetatchedResidenceDetails',
      'ResidentialApartmentDetails',
      'ResidentialTownhouseDetails',
      'ResidentialTerraceHouseDetails',
      'ResidentialVacantLandDetails'
    ]
      .map((refName) => this.$refs[refName])
      .filter((ref) => ref)
      .map((ref) => ref as unknown as IValuationFormStep)
      .map((ref) => ref.collectData())

    return ([] as FormValue[]).concat.apply([], refs)
  }
}
