import { ValidationResult } from '.'
import { FormDataset } from '..'
import IValidatable from './IValidatable'
import Validator from './Validator'

enum ValidationOperator {
  AND,
  OR
}

class ValidationRuleset implements IValidatable {
  public Rules: Validator[];

  constructor (rules: Validator[]) {
    this.Rules = rules
  }

  Validate (ds: FormDataset): ValidationResult {
    const results: ValidationResult = []

    this.Rules.forEach((r) => {
      const res = typeof r === 'function' ? r(ds) : r.Validate(ds)

      if (res) results.push(...res)
    })

    return results
  }
}

class ConditionalValidationRuleset implements IValidatable {
  public Predicate: (ds: FormDataset) => boolean;
  public Rules: Validator[];

  constructor (predicate: (ds: FormDataset) => boolean, rules: Validator[]) {
    this.Predicate = predicate
    this.Rules = rules
  }

  Validate (ds: FormDataset): ValidationResult {
    if (!this.Predicate(ds)) return null

    const results: ValidationResult = []

    this.Rules.forEach((r) => {
      const res = typeof r === 'function' ? r(ds) : r.Validate(ds)

      if (res) results.push(...res)
    })

    return results
  }
}

export { ValidationOperator, ValidationRuleset, ConditionalValidationRuleset }
