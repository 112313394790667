


















import { Vue, Component, Prop } from 'vue-property-decorator'
import { loadStripe, Stripe, StripeCardNumberElement, StripeElements } from '@stripe/stripe-js'
import { API, ServiceManager, ServiceType } from 'truemarket-modules/src/services'
import StripeService from 'truemarket-modules/src/services/payment/stripe/StripeService'
import Field from '../Forms/Field.vue'
import { InvoiceModel } from 'truemarket-modules/src/models/api/invoices'
import { GetContactModel } from 'truemarket-modules/src/models/api/crm'
import { GetProductModel } from 'truemarket-modules/src/models/api/products'

@Component({
  components: {
    Field
  }
})
export default class Checkout extends Vue {
  private stripe: Stripe | null = null
  private elements: StripeElements | null = null

  private stripeService = ServiceManager.Require<StripeService>(ServiceType.Stripe)

  @Prop()
  private readonly product!: GetProductModel;

  @Prop()
  private readonly contact!: GetContactModel | null;

  private api = ServiceManager.Require<API>(ServiceType.API)

  cardholderName = ''

  private invoice: InvoiceModel | null = null

  GetInvoice (): InvoiceModel | null {
    return this.invoice
  }

  async mounted () {
    this.stripe = await loadStripe(this.stripeService.GetPublishableKey())

    if (this.stripe) this.elements = this.stripe.elements()

    if (this.elements) {
      this.elements.create('cardNumber').mount(this.$refs.cardNumber as HTMLElement)
      this.elements.create('cardExpiry').mount(this.$refs.cardExpiration as HTMLElement)
      this.elements.create('cardCvc').mount(this.$refs.cardCvn as HTMLElement)
    }
  }

  Delay (): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, 4000)
    })
  }

  async OnBeforeNext (): Promise<boolean> {
    if (!this.stripe) return false

    const invoice = await this.api.Product.CreateInvoice(this.product.ProductId)
    this.invoice = invoice

    const pi = await this.api.Payment.CreateInvoicePaymentIntent(invoice.InvoiceId)

    const result = await this.stripe.confirmCardPayment(pi.ClientSecret, {
      payment_method: {
        card: this.elements?.getElement('cardNumber') as StripeCardNumberElement,
        billing_details: {
          name: this.cardholderName
        }
      }
    })

    return result.paymentIntent?.status === 'succeeded'
  }
}
