import { DropdownListItem } from './DropdownListItem'
import FormField from './FormField'

class DropdownField extends FormField {
  Items: DropdownListItem[];

  constructor (init: Partial<DropdownField>) {
    init.Type = 'select'

    super(init)

    this.Items = init.Items ?? []
  }
}

export default DropdownField
