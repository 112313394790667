































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Notice extends Vue {
  private active = false

  mounted (): void {
    if (!localStorage.getItem('showNotice')) {
      localStorage.setItem('showNotice', 'true')
      this.active = true
    } else if (localStorage.getItem('showNotice') === 'true') {
      this.active = true
    }
  }

  handleClick (): void {
    this.active = false

    if (this.active) {
      localStorage.setItem('showNotice', 'false')
    }
  }
}

