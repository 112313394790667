











import { FormField, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { Component, Vue } from 'vue-property-decorator'
import IValuationFormStep from '../IValuationFormStep'
import ValuationFormStep from './ValuationFormStep.vue'

@Component({
  components: {
    ValuationFormStep,
    Form
  }
})
export default class AddressDetails extends Vue implements IValuationFormStep {
  private fields: FormField[] = [
    new FormField({ Key: 'AddressDetails_Address', Type: 'address', Label: 'Property Address:', Placeholder: 'Enter Address Here', Required: true })
  ]

  public collectData (): FormValue[] {
    return (this.$refs.mainForm as Form).GetValues().Fields
  }

  mounted (): void {
    localStorage.setItem('showNotice', 'true')
  }
}
