








import { ValidationError } from '@/app_code/Forms/Validation'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FieldErrors extends Vue {
  @Prop()
  private errors!: ValidationError[];
}
