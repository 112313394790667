import FormValue from './FormValue'

class FormDataset {
  Fields: FormValue[];

  constructor (init: Partial<FormDataset>) {
    this.Fields = init.Fields ?? []
  }

  BuildModel<TModel> (): TModel {
    const base: Record<string, string> = {}

    this.Fields.forEach((f) => {
      // base[f.Key] = f.Value
      base[f.Key.split('_').reverse()[0]] = f.Value
    })

    return base as unknown as TModel
  }

  GetValue (key: string): string {
    return this.Fields.find((f) => f.Key === key)?.Value ?? ''
  }
}

export default FormDataset
