









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Field extends Vue {
  @Prop({ default: 'text' })
  private readonly type!: string;

  @Prop()
  private readonly placeholder!: string;

  @Prop({ required: true })
  private readonly label!: string;

  @Prop()
  private readonly autocomplete!: string;

  @Prop({ default: '' })
  private readonly value!: string;

  @Prop({ default: '3' })
  private readonly rows!: string;

  mounted () {
    if (this.type === 'date') {
      // const inputElement: HTMLInputElement | null = (this.$refs.input as HTMLInputElement)
      // inputElement.setAttribute('max', '')
    }
  }

  handleInput () {
    const val: string | null = (this.$refs.input as HTMLInputElement).value
    const inputElement: HTMLInputElement | null = (this.$refs.input as HTMLInputElement)

    if (this.type === 'date' || val === '') {
      inputElement.setAttribute('class', 'field__input')
    } else {
      inputElement.setAttribute('class', 'field__input has-value')
    }

    if (val === '') {
      this.$emit('input', null)
    } else {
      this.$emit('input', val)
    }
  }
}
