













import { Component, Vue, Prop } from 'vue-property-decorator'
import { DropdownListItem } from '../../../../app_code/Forms'

@Component
export default class DropdownList extends Vue {
  @Prop({ default: 'text' })
  private readonly type!: string;

  @Prop()
  private readonly placeholder!: string;

  @Prop({ required: true })
  private readonly label!: string;

  @Prop({ required: true })
  private readonly value!: string;

  @Prop({ required: true })
  private readonly items!: DropdownListItem[];

  handleInput () {
    const input = this.$refs.input as HTMLInputElement

    this.$emit('input', input.value)
  }
}
