




















import { AddressModel } from 'truemarket-modules/src/models/api'
import { AutocompleteResultModel } from 'truemarket-modules/src/models/api/google/AutocompleteResultModel'
import { Services } from 'truemarket-modules/src/services'
import Utils from 'truemarket-modules/src/Utils'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class AddressField extends Vue {
  @Prop({ default: 'text' })
  private readonly type!: string;

  @Prop()
  private readonly placeholder!: string;

  @Prop({ required: true })
  private readonly label!: string;

  @Prop()
  private readonly autocomplete!: string;

  @Prop({ default: '' })
  private readonly value!: string;

  @Prop({ default: '3' })
  private readonly rows!: string;

  private val = this.value

  handleInput (): void {
    const val: string | null = (this.$refs.input as HTMLInputElement).value

    this.$emit('input', val)

    this.val = val

    this.fetchData()
  }

  private gmapsSession = Utils.GetGuid()

  results: AutocompleteResultModel[] = []

  private throttler: (() => void) | undefined = undefined

  fetchData (): void {
    if (this.throttler === undefined) {
      this.throttler = Utils.Throttle(1000, () => {
        Services.API.Google.AddressAutocomplete(this.val, 'country:au', this.gmapsSession).then((ac) => {
          this.results = ac.Predictions
        })
      })
    }

    if (this.val.length < 5) {
      this.results = []
      return
    }

    this.throttler()
  }

  selectResult (result: AutocompleteResultModel): void {
    Services.API.Google.GetPlaceDetails(result.PlaceId, this.gmapsSession).then((res) => {
      const place = res.Result

      const address: AddressModel = {
        UnitNumber: place.AddressComponents.find((ac) => ac.Types.includes('subpremise'))?.LongName,
        StreetNumber: place.AddressComponents.find((ac) => ac.Types.includes('street_number'))?.LongName ?? '',
        StreetName: place.AddressComponents.find((ac) => ac.Types.includes('route'))?.LongName ?? '',
        Suburb: place.AddressComponents.find((ac) => ac.Types.includes('locality'))?.LongName ?? '',
        State: place.AddressComponents.find((ac) => ac.Types.includes('administrative_area_level_1'))?.LongName ?? '',
        Postcode: place.AddressComponents.find((ac) => ac.Types.includes('postal_code'))?.LongName ?? '',
        FormattedAddress: '',
        FormattedAddressLines: []
      }

      console.log(address)

      const formattedComponents = []
      if (address.UnitNumber) {
        formattedComponents.push(address.UnitNumber + '/' + address.StreetNumber)
      } else if (address.StreetNumber) {
        formattedComponents.push(address.StreetNumber)
      }

      if (address.StreetName) formattedComponents.push(address.StreetName)
      if (address.Suburb) formattedComponents.push(address.Suburb)
      if (address.Postcode) formattedComponents.push(address.Postcode)

      this.val = formattedComponents.join(' ')

      this.$emit('input', JSON.stringify(address))
      this.results = []
    })
  }
}
